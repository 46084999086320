import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { format, addMonths, subMonths } from 'date-fns';
import axios from 'axios';
import { ReactComponent as CalendarIcon } from '../../images/icons/Calendar.svg';

// Styles
import styles from './UserProgress.module.css';

// Utils
import { getHeaderBackground, mapHabitAverages } from './ProgressUtils';

// Components
import ClientHeader from "../clientHeader/clientHeader";
import { CurrentStandings } from './tiles/CurrentStandings';
import { LoginStreak } from './tiles/LoginStreak';
import { DailyScore } from './tiles/DailyScore';
import { MonthlyLogins } from './tiles/MonthlyLogins';
import { AchievementSquareTiles } from './tiles/AchievementSquareTiles';
import { VictoryModal, MonthPickerModal } from './modals/ProgressModals';
import { ActivityEarnings } from './tiles/ActivityEarnings';
import { DailyHabitAverages } from './tiles/DailyHabitAverages';

const UserProgress = (props) => {
  const [currentStreak, setCurrentStreak] = useState(null);
  const [averageDailyScore, setAverageDailyScore] = useState(null);
  const [lifetimeScore, setLifetimeScore] = useState(null);
  const [healthGoal, setHealthGoal] = useState(null);
  const [dailyGoal, setDailyGoal] = useState(null);
  const [currentBadge, setCurrentBadge] = useState(null);
  const [selectedMonthScore, setSelectedMonthScore] = useState(0);
  const [recentVictory, setRecentVictory] = useState(null);
  const [monthlyVictories, setMonthlyVictories] = useState([]);
  const [habitAverages, setHabitAverages] = useState([]);
  const [monthlyLogins, setMonthlyLogins] = useState(0);
  const [monthlyVideosWatched, setMonthlyVideosWatched] = useState(0);
  const [engagingEarnings, setEngagingEarnings] = useState('0');
  const [loggingEarnings, setLoggingEarnings] = useState('0');
  const [bonusEarnings, setBonusEarnings] = useState('0');
  const [isVictoryModalVisible, setIsVictoryModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [rankProgress, setRankProgress] = useState(0);
  
  const { user } = props;

  const fetchAllData = async () => {
    try {
      // Fetch streak data
      const streakResponse = await axios.get('/score/streak');
      setCurrentStreak(streakResponse.data.results.currentStreak || 1);
    } catch (error) {
      console.error('Error fetching streak data:', error);
    }

    try {
      // Fetch wellness score
      const wellnessResponse = await axios.get('/user/progress/wellness-scores', {
        params: { date: selectedDate.toISOString() }
      });
      setAverageDailyScore(wellnessResponse.data.result || null); // Set initial state back to null to ensure loading state is shown
    } catch (error) {
      console.error('Error fetching wellness scores:', error);
    }

    try {
      // Fetch client details
      const clientResponse = await axios.get(`/user/client-detail/${user.username}`);
      const clientDetails = clientResponse.data.user || {};
      setLifetimeScore(clientDetails.lifetime_wellness_score);
      setHealthGoal(clientDetails.healthgoal);
      setDailyGoal(clientDetails.daily_goal);
    } catch (error) {
      console.error('Error fetching client details:', error);
    }

    try {
      // Fetch game data
      const badgeResponse = await axios.get('/game/current/badges');
      const currentBadge = badgeResponse.data.result.current_badge.name || '';
      setCurrentBadge(currentBadge);
    } catch (error) {
      console.error('Error fetching badge data:', error);
    }

    try {
      // Get level achievements
      const achievementsResponse = await axios.get('/user/progress/level-achievements');
      const achievements = achievementsResponse.data.result || [];
      const currentRankAchievements = user && user.game ? achievements.filter(
        achievement => achievement.rank_id === user.game.rank_id
      ).length : 0;
      setRankProgress(currentRankAchievements);
    } catch (error) {
      console.error('Error fetching level achievements:', error);
    }

    try {
      // Fetch monthly data
      const formattedDate = format(selectedDate, 'yyyy-MM-dd');
      const scoreResponse = await axios.get(`/score/month/${formattedDate}`);
      const monthScore = parseInt(scoreResponse.data.results || 0);
      setSelectedMonthScore(monthScore);
    } catch (error) {
      console.error('Error fetching monthly score:', error);
    }

    try {
      // Fetch victories
      const { data: { result: { victories } } } = await axios.get('/victory/get/member/victories/');
      const currentMonth = selectedDate.getMonth();
      const currentYear = selectedDate.getFullYear();
      
      const currentMonthVictories = victories.filter(victory => {
        const victoryDate = new Date(victory.victory.achievement_date);
        return victoryDate.getMonth() === currentMonth && 
               victoryDate.getFullYear() === currentYear;
      });
      setRecentVictory(currentMonthVictories[0] || null);
      setMonthlyVictories(currentMonthVictories);
    } catch (error) {
      console.error('Error fetching victories:', error);
    }

    try {
      // Fetch habit averages
      const { data: { result } } = await axios.get('/user/progress/tiles', {
        params: { start_date: selectedDate.toISOString() }
      });
      const habitAverages = mapHabitAverages(result);
      setHabitAverages(habitAverages);
    } catch (error) {
      console.error('Error fetching habit averages:', error);
    }

    try {
      // Fetch monthly logins
      const loginResponse = await axios.get('/user/progress/monthly/logins', {
        params: { date: selectedDate.toISOString() }
      });
      setMonthlyLogins(loginResponse.data.result || 0);
    } catch (error) {
      console.error('Error fetching monthly logins:', error);
    }

    try {
      // Fetch activity earnings
      const earningsResponse = await axios.get('/user/progress/activity/earnings', {
        params: { start_date: selectedDate.toISOString() }
      });
      const earnings = earningsResponse.data.result || [];
      
      setEngagingEarnings((earnings.find(e => e.name === 'Engaging') || {}).average || '0');
      setLoggingEarnings((earnings.find(e => e.name === 'Logging') || {}).average || '0');
      setBonusEarnings((earnings.find(e => e.name === 'Bonus') || {}).average || '0');
    } catch (error) {
      console.error('Error fetching activity earnings:', error);
    }
  };

  useEffect(() => {
    fetchAllData();
    const fetchVideoCount = async () => {
      try {
        const videoResponse = await axios.get('/user/progress/videos-watched-count');
        let count = 0;
        if (videoResponse.data && videoResponse.data.result) {
          count = videoResponse.data.result.videos_watched || 0;
        }
        setMonthlyVideosWatched(count);
      } catch (error) {
        console.error('Error fetching video watched count:', error);
        setMonthlyVideosWatched(0); // default to 0 on error
      }
    };
    fetchVideoCount();
  }, []);

  useEffect(() => {
    // Reset states when the month changes
    setAverageDailyScore(null);
    setLifetimeScore(null);
    setCurrentStreak(null);
    setHealthGoal(null);
    setDailyGoal(null);
    setHabitAverages([]);
    
    // Fetch new data
    fetchAllData();
  }, [selectedDate]);

  const nextMonth = () => {
    const newDate = addMonths(selectedDate, 1);
    setSelectedDate(newDate);
  };

  const prevMonth = () => {
    const newDate = subMonths(selectedDate, 1);
    setSelectedDate(newDate);
  };

  return (
    <>
      <div className="client-container">
        <ClientHeader backUrl={`/member/clientDash/${user.username}`} headerBackground={getHeaderBackground(selectedMonthScore)} />
      </div>
      <div className={styles.container}>
        <div className={styles.pageContainer}>
          <div className={styles.headerContainer}>
            <h1 className={styles.pageTitle}>
              {format(selectedDate, 'MMMM')} Progress
            </h1>
            <button 
              className={styles.historyButton}
              onClick={() => setShowMonthPicker(true)}
            >
              <span className={styles.historyText}>History</span>
              <CalendarIcon width={11} height={11} style={{ color: '#ffffff' }} />
            </button>
          </div>

          {showMonthPicker && (
            <MonthPickerModal
              isVisible={showMonthPicker}
              onClose={() => setShowMonthPicker(false)}
              selectedDate={selectedDate}
              onPrevMonth={prevMonth}
              onNextMonth={nextMonth}
            />
          )}
          <h2 className={styles.sectionHeader}>My Standings</h2>
          <CurrentStandings
            lifetimeScore={lifetimeScore}
            healthGoal={healthGoal}
            user={user}
            currentBadge={currentBadge}
          />

          <LoginStreak currentStreak={currentStreak} />

          <h2 className={styles.sectionHeader}>My Achievements</h2>
          <div className={styles.content}>
            <div className={styles.achievementsList}>
              <div className={styles.achievementRow}>
                <DailyScore 
                  averageDailyScore={averageDailyScore}
                  dailyGoal={dailyGoal}
                />
                <MonthlyLogins
                  selectedDate={selectedDate}
                  monthlyLogins={monthlyLogins}
                />
              </div>

              <AchievementSquareTiles
                selectedDate={selectedDate}
                recentVictory={recentVictory}
                selectedMonthScore={selectedMonthScore}
                currentBadge={currentBadge}
                user={user}
                onVictoryPress={() => setIsVictoryModalVisible(true)}
                rankProgress={rankProgress}
              />
            </div>
          </div>

          <ActivityEarnings
            selectedDate={selectedDate}
            engagingEarnings={engagingEarnings}
            loggingEarnings={loggingEarnings}
            bonusEarnings={bonusEarnings}
          />
          <h2 className={styles.sectionHeader}>My Daily Averages</h2>
          <DailyHabitAverages habitAverages={habitAverages} />
        </div>
      </div>
      {isVictoryModalVisible && (
        <VictoryModal 
          isVisible={isVictoryModalVisible}
          onClose={() => setIsVictoryModalVisible(false)}
          selectedDate={selectedDate}
          monthlyVictories={monthlyVictories}
          currentStreak={currentStreak}
          monthlyVideosWatched={monthlyVideosWatched}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(UserProgress);