import React from 'react';

// styles
import styles from './ranks.module.css';

// components
import {RANKS_IDS, RANKS} from "../../../serverVariables/ranks";
import RankMedal from "../../rankMedal/rankMedal";

const Ranks = props => {
    // props
    const {rank, setRank, rankName} = props;

    function changeRank(rank, name){
        setRank(rank, name);
    }
    return (
        <div className={styles.container}>
            <div className={styles.howToRow}>
                <span className={styles.rankName}><strong>Rank:</strong> <span className={styles.name}>{rankName}</span></span>
            </div>

            <div className={styles.allRanks} data-testID="rankBadge">
                <div className={styles.rankContainer} onClick={() => changeRank( RANKS_IDS.BRONZE, RANKS.BRONZE)}>
                    <RankMedal className={`${styles.rank} ${rank === RANKS_IDS.BRONZE ? styles.large : ''}`} rank={RANKS_IDS.BRONZE}/>
                    {rank !== RANKS_IDS.BRONZE ? <div className={styles.overlay}/> : null}
                </div>
                <div className={styles.rankContainer} onClick={() => changeRank(RANKS_IDS.SILVER, RANKS.SILVER)}>
                    <RankMedal className={`${styles.rank} ${rank === RANKS_IDS.SILVER ? styles.large : ''}`} rank={RANKS_IDS.SILVER}/>
                    {rank !== RANKS_IDS.SILVER ? <div className={styles.overlay}/> : null}
                </div>
                <div className={styles.rankContainer} onClick={() => changeRank(RANKS_IDS.GOLD, RANKS.GOLD)}>
                    <RankMedal className={`${styles.rank} ${rank === RANKS_IDS.GOLD ? styles.large : ''}`} rank={RANKS_IDS.GOLD}/>
                    {rank !== RANKS_IDS.GOLD ? <div className={styles.overlay}/> : null}
                </div>
                <div className={styles.rankContainer} onClick={() => changeRank(RANKS_IDS.PLATINUM, RANKS.PLATINUM)}>
                    <RankMedal className={`${styles.rank} ${rank === RANKS_IDS.PLATINUM ? styles.large : ''}`} rank={RANKS_IDS.PLATINUM}/>
                    {rank !== RANKS_IDS.PLATINUM ? <div className={styles.overlay}/> : null}
                </div>
                <div className={styles.rankContainer} onClick={() => changeRank(RANKS_IDS.DIAMOND, RANKS.DIAMOND)}>
                    <RankMedal className={`${styles.diamondRank} ${rank === RANKS_IDS.DIAMOND ? styles.large : ''}`} rank={RANKS_IDS.DIAMOND}/>
                    {rank !== RANKS_IDS.DIAMOND ? <div className={styles.overlay}/> : null}
                </div>
            </div>

        </div>
    )
}
export default Ranks;