export const Trailblazer_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_test.png";
export const Trailblazer_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Trailblazer50x50.png";
export const Trailblazer_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Trailblazer200x200.png";
export const Trailblazer_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Trailblazer200x200.png";
export const Courageous_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Courageous200x200.png";
export const Courageous_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Courageous50x50.png";
export const Courageous_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Courageous200x200.png";
export const Courageous_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Courageous50x50.png";
export const Explorer_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Explorer200x200.png";
export const Explorer_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Explorer50x50.png";
export const Explorer_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Explorer200x200.png";
export const Explorer_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Explorer50x50.png";
export const Accepting_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Accepting200x200.png";
export const Accepting_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Accepting50x50.png";
export const Accepting_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Accepting200x200.png";
export const Accepting_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Accepting50x50.png";
export const Enthusiastic_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_test+copy+4.png";
export const Enthusiastic_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Enthusiastic50x50.png";
export const Enthusiastic_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Enthusiastic200x200.png";
export const Enthusiastic_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Enthusiastic50x50.png";
export const Proactive_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Proactive200x200.png";
export const Proactive_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Proactive50x50.png";
export const Proactive_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Proactive200x200.png";
export const Proactive_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Proactive50x50.png";
export const Creative_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Creative200x200.png";
export const Creative_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Creative50x50.png";
export const Creative_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Creative200x200.png";
export const Creative_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Creative50x50.png";
export const Perseverance_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Perserverance200x200.png";
export const Perseverance_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Perserverance50x50.png";
export const Perseverance_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Perserverance200x200.png";
export const Perseverance_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Perserverance50x50.png";
export const Tenacity_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Tenacity200x200.png";
export const Tenacity_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Tenacity50x50+.png";
export const Tenacity_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Tenacity200x200.png";
export const Tenacity_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Tenacity50x50+.png";
export const Determined_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Determined200x200.png";
export const Determined_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Determined50x50.png";
export const Determined_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Determined200x200.png";
export const Determined_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Determined50x50.png";
export const Committed_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Committed200x200+copy.png";
export const Committed_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Committed50x50+copy.png";
export const Committed_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Committed200x200+copy.png";
export const Committed_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Committed50x50+copy.png";
export const Dependable_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Dependable200x200.png";
export const Dependable_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Dependable50x50.png";
export const Dependable_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Dependable200x200.png";
export const Dependable_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Dependable200x200.png";
export const Grit_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Grit200x200.png";
export const Grit_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Grit50x50.png";
export const Grit_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Grit200x200.png";
export const Grit_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_Grit50x50.png";
export const CheckIn8_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_GymCheckin8x200x200.png";
export const CheckIn8_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_GymCheckin8x50x50.png";
export const CheckIn15_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_GymCheckin15x200x200.png";
export const CheckIn15_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_GymCheckin15x50x50.png";
export const CheckIn8_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_GymCheckin8x200x200.png";
export const CheckIn8_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_GymCheckin8x50x50.png";
export const CheckIn15_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_GymCheckin815x200x200.png";
export const CheckIn15_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_GymCheckin15x50x50.png";
export const Bold_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Bold200x200.png";
export const Bold_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Bold50x50.png";
export const Bold_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Bold200x200.png";
export const Bold_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Bold50x50.png";
export const Innovator_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Innovator200x200.png";
export const Innovator_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Innovator50x50.png";
export const Innovator_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Innovator200x200.png";
export const Innovator_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Innovator50x50.png";
export const Dedicated_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Dedicated200x200.png";
export const Dedicated_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Dedicated50x50.png";
export const Dedicated_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Dedicated200x200.png";
export const Dedicated_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Dedicated50x50.png";
export const Curiosity_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Curiousity200x200.png";
export const Curiosity_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Curiousity50x50.png";
export const Curiosity_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Curiousity200x200.png";
export const Curiosity_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Curiousity50x50.png";
export const Motivated_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Motivated200x200.png";
export const Motivated_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Motivated50x50.png";
export const Motivated_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Motivated200x200.png";
export const Motivated_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Motivated50x50.png";
export const Capable_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Capable200x200.png";
export const Capable_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Capable50x50.png";
export const Capable_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Capable200x200.png";
export const Capable_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Capable50x50.png";
export const Endurance_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Endurance200x200.png";
export const Endurance_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Endurance50x50.png";
export const Endurance_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Endurance200x200.png";
export const Endurance_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Endurance50x50.png";
export const Driven_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Driven200x200.png";
export const Driven_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Driven50x50.png";
export const Driven_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Driven200x200.png";
export const Driven_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Driven50x50.png";
export const Engaged_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Engaged200x200.png";
export const Engaged_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Engaged50x50.png";
export const Engaged_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Engaged200x200.png";
export const Engaged_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Engaged50x50.png";
export const Resolute_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Resolute200x200.png";
export const Resolute_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Resolute50x50.png";
export const Resolute_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Resolute200x200.png";
export const Resolute_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Resolute50x50.png";
export const Empowered_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Empowered200x200.png";
export const Empowered_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Empowered50x50.png";
export const Empowered_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Empowered200x200.png";
export const Empowered_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Empowered50x50.png";
export const Accomplished_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Accomplished200x200.png";
export const Accomplished_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Accomplished50x50.png";
export const Accomplished_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Accomplished200x200.png";
export const Accomplished_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Accomplished50x50.png";
export const Watched_15_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Victories_DropShadow_VODWatched15x_200x200.png";
export const Watched_8_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Victories_DropShadow_VODWatched8x_200x200.png";

// gold
// 1
export const Pioneer_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Pioneer200x200.png";
export const Pioneer_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Pioneer50x50.png";
export const Pioneer_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Pioneer200x200.png";
export const Pioneer_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Pioneer50x50.png";

// 2
export const Fearless_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Fearless200x200.png";
export const Fearless_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Fearless50x50.png";
export const Fearless_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Fearless200x200.png";
export const Fearless_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Fearless50x50.png";

// 3
export const Adventurer_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Adventurer200x200.png";
export const Adventurer_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Adventurer50x50.png";
export const Adventurer_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Adventurer200x200.png";
export const Adventurer_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Adventurer50x50.png";

// 4
export const Adaptable_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Adaptable200x200.png";
export const Adaptable_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Adaptable50x50.png";
export const Adaptable_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Adaptable200x200.png";
export const Adaptable_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Adaptable50x50.png";

// 5
export const Energized_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Energized200x200.png";
export const Energized_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Energized50x50.png";
export const Energized_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Energized200x200.png";
export const Energized_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Energized50x50.png";

//6
export const Resourceful_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Resourceful200x200.png";
export const Resourceful_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Resourceful50x50.png";
export const Resourceful_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Resourceful200x200.png";
export const Resourceful_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Resourceful50x50.png";

//7
export const Persistent_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Persistent200x200.png";
export const Persistent_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Persistent50x50.png";
export const Persistent_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Persistent200x200.png";
export const Persistent_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Persistent50x50.png";

//8
export const Resilient_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Resilient200x200-77.png";
export const Resilient_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Resilient50x50-78.png";

export const Resilient_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Resilient200x200-79.png";
export const Resilient_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Resilient50x50-80.png";

//9
export const Receptive_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Receptive200x200.png";
export const Receptive_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Receptive50x50.png";
export const Receptive_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Receptive200x200.png";
export const Receptive_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Receptive50x50.png";

//10
export const Transformed_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Transformed200x200.png";
export const Transformed_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Transformed50x50.png";
export const Transformed_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Transformed200x200.png";
export const Transformed_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Transformed50x50.png";

//11
export const Inspiring_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Inspiring200x200.png";
export const Inspiring_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Inspiring50x50.png";
export const Inspiring_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Inspiring200x200.png";
export const Inspiring_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Inspiring50x50.png";

//12
export const Reflective_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Reflective200x200.png";
export const Reflective_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_DropShadow_Reflective50x50.png";

// did not work
export const Reflective_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Reflective200x200.png";
export const Reflective_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Badges_NoDropShadow_Reflective50x50.png";

// Step Victory URLs (with drop shadow)
export const Steps_100k_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_100k_Dropshadow_200x200.png";
export const Steps_150k_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_150k_Dropshadow_200x200.png";
export const Steps_200k_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_200k_Dropshadow_200x200.png";
export const Steps_300k_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_300k_Dropshadow_200x200.png";

// Non-shadow versions
export const Steps_100k =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_100k_200x200.png";
export const Steps_150k =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_150k_200x200.png";
export const Steps_200k =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_200k_200x200.png";
export const Steps_300k =
  "https://treo.s3.us-east-2.amazonaws.com/assets/victories/Victories_Wearables_300k_200x200.png";

// Ready
export const Ready_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Ready_200x200.png";
export const Ready_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Ready_50x50.png";
  export const Ready_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Ready_200x200.png";
export const Ready_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Ready_50x50.png";


// Responsive
export const Responsive_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Responsive_200x200.png";
export const Responsive_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Responsive_50x50.png";
  export const Responsive_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Responsive_200x200.png";
export const Responsive_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Responsive_50x50.png";


// Ownership
export const Ownership_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Ownership_200x200.png";
export const Ownership_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Ownership_50x50.png";
  export const Ownership_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Ownership_200x200.png";
export const Ownership_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Ownership_50x50.png";


// Open
export const Open_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Open_200x200.png";
export const Open_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Open_50x50.png";
  export const Open_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Open_200x200.png";
export const Open_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Open_50x50.png";


// Willing
export const Willing_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Willing_200x200.png";
export const Willing_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Willing_50x50.png";

  export const Willing_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Willing_200x200.png";
export const Willing_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Willing_50x50.png";


// Striving
export const Striving_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Striving_200x200.png";
export const Striving_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Striving_50x50.png";

  export const Striving_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Striving_200x200.png";
export const Striving_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Striving_50x50.png";


// Tough
export const Tough_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Tough_200x200.png";
export const Tough_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Tough_50x50.png";
  export const Tough_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Tough_200x200.png";
export const Tough_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Tough_50x50.png";


// Thoughtful
export const Thoughtful_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Thoughtful_200x200.png";
export const Thoughtful_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Thoughtful_50x50.png";
  export const Thoughtful_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Thoughtful_200x200.png";
export const Thoughtful_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Thoughtful_50x50.png";


// Thinker
export const Thinker_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Thinker_200x200.png";
export const Thinker_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Thinker_50x50.png";
  export const Thinker_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Thinker_200x200.png";
export const Thinker_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Thinker_50x50.png";


// Humble
export const Humble_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Humble_200x200.png";
export const Humble_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Humble_50x50.png";
  export const Humble_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Humble_200x200.png";
export const Humble_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Humble_50x50.png";


// Skilled
export const Skilled_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Skilled_200x200.png";

  // did not work
export const Skilled_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Skilled_50x50.png";
  export const Skilled_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Skilled_200x200.png";

  // didnt work
export const Skilled_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Skilled_50x50.png";


// Activated
export const Activated_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badge_Platinum_Activated_200x200.png";
export const Activated_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badge_Platinum_Activated_50x50.png";
  export const Activated_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/200x200/Badges_Platinum_Dropshadow_Activated_200x200.png";
export const Activated_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Platinum/50x50/Badges_Platinum_Dropshadow_Activated_50x50.png";











// Evolve
export const Evolve_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Evolve_200x200.png";
export const Evolve_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Evolve_50x50.png";

  export const Evolve_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Evolve_200x200.png";
export const Evolve_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Evolve_50x50.png";


// Focused
export const Focused_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Focused_200x200.png";
export const Focused_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Focused_50x50.png";
  export const Focused_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Focused_200x200.png";
export const Focused_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Focused_50x50.png";


// Veteran
export const Veteran_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Veteran_200x200.png";
export const Veteran_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Veteran_50x50.png";
  export const Veteran_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Veteran_200x200.png";
export const Veteran_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Veteran_50x50.png";


// Leader
export const Leader_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Leader_200x200.png";
export const Leader_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Leader_50x50.png";
  export const Leader_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Leader_200x200.png";
export const Leader_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Leader_50x50.png";


// Devoted
export const Devoted_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Devoted_200x200.png";
export const Devoted_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Devoted_50x50.png";
  export const Devoted_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Devoted_200x200.png";
export const Devoted_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Devoted_50x50.png";


// Consistent
export const Consistent_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Consistent_200x200.png";
export const Consistent_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Consistent_50x50.png";
export const Consistent_200_shadow =
"https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Consistent_200x200.png";
export const Consistent_50_shadow =
"https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Consistent_50x50.png";


// Steadfast
export const Steadfast_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Steadfast_200x200.png";
export const Steadfast_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Steadfast_50x50.png";
  export const Steadfast_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Steadfast_200x200.png";
export const Steadfast_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Steadfast_50x50.png";


// Visionary
export const Visionary_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Visionary_200x200.png";
export const Visionary_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Visionary_50x50.png";
  export const Visionary_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Visionary_200x200.png";
export const Visionary_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Visionary_50x50.png";


// Proficient
export const Proficient_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Proficient_200x200.png";
export const Proficient_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Proficient_50x50.png";
  export const Proficient_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Proficient_200x200.png";
export const Proficient_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Proficient_50x50.png";


// Mindful
export const Mindful_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Mindful_200x200.png";
export const Mindful_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Mindful_50x50.png";
  export const Mindful_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Mindful_200x200.png";
export const Mindful_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Mindful_50x50.png";


// Insightful
export const Insightful_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Insightful_200x200.png";
export const Insightful_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Insightful_50x50.png";
  export const Insightful_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Insightful_200x200.png";
export const Insightful_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Insightful_50x50.png";


// Dynamic
export const Dynamic_200 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badge_Diamond_Dynamic_200x200.png";
export const Dynamic_50 =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badge_Diamond_Dynamic_50x50.png";

  export const Dynamic_200_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/200x200/Badges_Diamond_Dropshadow_Dynamic_200x200.png";
export const Dynamic_50_shadow =
  "https://treo.s3.us-east-2.amazonaws.com/assets/badges/Diamond/50x50/Badges_Diamond_Dropshadow_Dynamic_50x50.png";

export const BADGES = {
  TRAILBLAZER: "trailblazer",
  TRAILHEAD: "trailhead",
  COURAGEOUS: "courageous",
  EXPLORER: "explorer",
  ACCEPTING: "accepting",
  ENTHUSIASTIC: "enthusiastic",
  PROACTIVE: "proactive",
  CREATIVE: "creative",
  PERSEVERANCE: "perseverance",
  TENACITY: "tenacity",
  DETERMINED: "determined",
  COMMITTED: "committed",
  DEPENDABLE: "dependable",
  GRIT: "grit",
  CHECK_IN_8: "checkIn8",
  CHECK_IN_15: "checkIn15",
  BOLD: "bold",
  INNOVATOR: "innovator",
  DEDICATED: "dedicated",
  CURIOSITY: "curiosity",
  MOTIVATED: "motivated",
  CAPABLE: "capable",
  ENDURANCE: "endurance",
  DRIVEN: "driven",
  ENGAGED: "engaged",
  RESOLUTE: "resolute",
  EMPOWERED: "empowered",
  ACCOMPLISHED: "accomplished",

  PIONEER: "pioneer",
  FEARLESS: "fearless",
  ADVENTURER: "adventurer",
  ADAPTABLE: "adaptable",
  ENERGIZED: "energized",
  RESOURCEFUL: "resourceful",
  PERSISTENT: "persistent",
  RESILIENT: "resilient",
  RECEPTIVE: "receptive",
  TRANSFORMED: "transformed",
  INSPIRING: "inspiring",
  REFLECTIVE: "reflective",


  READY: "ready",
  RESPONSIVE: "responsive",
  OWNERSHIP: "ownership",
  OPEN: "open",
  WILLING: "willing",
  STRIVING: "striving",
  TOUGH: "tough",
  THOUGHTFUL: "thoughtful",
  THINKER: "thinker",
  HUMBLE: "humble",
  SKILLED: "skilled",
  ACTIVATED: "activated",

  EVOLVE: "evolve",
  FOCUSED: "focused",
  VETERAN: "veteran",
  LEADER: "leader",
  DEVOTED: "devoted",
  CONSISTENT: "consistent",
  STEADFAST: "steadfast",
  VISIONARY: "visionary",
  PROFICIENT: "proficient",
  MINDFUL: "mindful",
  INSIGHTFUL: "insightful",
  DYNAMIC: "dynamic",
};

export const BADGE_IDS = {
  TRAILBLAZER: 0,
  TRAILHEAD: 0,
  COURAGEOUS: 1,
  EXPLORER: 2,
  ACCEPTING: 3,
  ENTHUSIASTIC: 4,
  PROACTIVE: 5,
  CREATIVE: 6,
  PERSEVERANCE: 7,
  TENACITY: 8,
  DETERMINED: 9,
  COMMITTED: 10,
  DEPENDABLE: 11,
  GRIT: 12,
  BOLD: 13,
  INNOVATOR: 14,
  DEDICATED: 15,
  CURIOSITY: 16,
  MOTIVATED: 17,
  CAPABLE: 18,
  ENDURANCE: 19,
  DRIVEN: 20,
  ENGAGED: 21,
  RESOLUTE: 22,
  EMPOWERED: 23,
  ACCOMPLISHED: 24,

  PIONEER: 25,
  FEARLESS: 26,
  ADVENTURER: 27,
  ADAPTABLE: 28,
  ENERGIZED: 29,
  RESOURCEFUL: 30,
  PERSISTENT: 31,
  RESILIENT: 32,
  RECEPTIVE: 33,
  TRANSFORMED: 34,
  INSPIRING: 35,
  REFLECTIVE: 36,

  READY: 37,
  RESPONSIVE: 38,
  OWNERSHIP: 39,
  OPEN: 40,
  WILLING: 41,
  STRIVING: 42,
  TOUGH: 43,
  THOUGHTFUL: 44,
  THINKER: 45,
  HUMBLE: 46,
  SKILLED: 47,
  ACTIVATED: 48,
  
  EVOLVE: 49,
  FOCUSED: 50,
  VETERAN: 51,
  LEADER: 52,
  DEVOTED: 53,
  CONSISTENT: 54,
  STEADFAST: 55,
  VISIONARY: 56,
  PROFICIENT: 57,
  MINDFUL: 58,
  INSIGHTFUL: 59,
  DYNAMIC: 60,
};

export const BADGE_URLS = {
  TRAILBLAZER: Trailblazer_200,
  TRAILHEAD: Trailblazer_200,
  COURAGEOUS: Courageous_200,
  EXPLORER: Explorer_200,
  ACCEPTING: Accepting_200,
  ENTHUSIASTIC: Enthusiastic_200,
  PROACTIVE: Proactive_200,
  CREATIVE: Creative_200,
  PERSEVERANCE: Perseverance_200,
  TENACITY: Tenacity_200,
  DETERMINED: Determined_200,
  COMMITTED: Committed_200,
  DEPENDABLE: Dependable_200,
  GRIT: Grit_200,

  TRANSFORMED: Transformed_200,
  RESOURCEFUL: Resourceful_200,
  RESILIENT: Resilient_200,
  PIONEER: Pioneer_200,
  PERSISTENT: Persistent_200,
  INSPIRING: Inspiring_200,
  FEARLESS: Fearless_200,
  ENERGIZED: Energized_200,
  RECEPTIVE: Receptive_200,
  ADVENTURER: Adventurer_200,
  ADAPTABLE: Adaptable_200,
  REFLECTIVE: Reflective_200_shadow,

  READY: Ready_200,
  RESPONSIVE: Responsive_200,
  OWNERSHIP: Ownership_200,
  OPEN: Open_200,
  WILLING: Willing_200,
  STRIVING: Striving_200,
  TOUGH: Tough_200,
  THOUGHTFUL: Thoughtful_200,
  THINKER: Thinker_200,
  HUMBLE: Humble_200,
  SKILLED: Skilled_200,
  ACTIVATED: Activated_200,






  EVOLVE: Evolve_200,
  FOCUSED: Focused_200,
  VETERAN: Veteran_200,
  LEADER: Leader_200,
  DEVOTED: Devoted_200,
  CONSISTENT: Consistent_200,
  STEADFAST: Steadfast_200,
  VISIONARY: Visionary_200,
  PROFICIENT: Proficient_200,
  MINDFUL: Mindful_200,
  INSIGHTFUL: Insightful_200,
  DYNAMIC: Dynamic_200,
};
