import React from "react";
/* eslint-disable */
// components
import {
  BADGES,
  BADGE_IDS,
  Committed_200_shadow,
  Committed_50_shadow,
  Committed_200,
  Dependable_50_shadow,
  Dependable_50,
  Dependable_200,
  Dependable_200_shadow,
  Committed_50,
  Determined_200,
  Determined_200_shadow,
  Determined_50_shadow,
  Determined_50,
  Tenacity_200,
  Tenacity_200_shadow,
  Tenacity_50_shadow,
  Tenacity_50,
  Perseverance_200,
  Perseverance_200_shadow,
  Perseverance_50_shadow,
  Perseverance_50,
  Creative_50_shadow,
  Creative_50,
  Creative_200,
  Creative_200_shadow,
  Trailblazer_200,
  Trailblazer_50,
  Trailblazer_200_shadow,
  Trailblazer_50_shadow,
  Courageous_200,
  Courageous_50,
  Courageous_200_shadow,
  Courageous_50_shadow,
  Explorer_200,
  Explorer_50,
  Explorer_200_shadow,
  Explorer_50_shadow,
  Accepting_200,
  Accepting_50,
  Accepting_200_shadow,
  Accepting_50_shadow,
  Enthusiastic_200,
  Enthusiastic_50,
  Enthusiastic_200_shadow,
  Enthusiastic_50_shadow,
  Proactive_200,
  Proactive_50,
  Proactive_200_shadow,
  Proactive_50_shadow,
  Grit_200,
  Grit_50,
  Grit_200_shadow,
  Grit_50_shadow,
  Bold_50_shadow,
  Bold_50,
  Bold_200_shadow,
  Bold_200,
  Innovator_50_shadow,
  Innovator_50,
  Innovator_200_shadow,
  Innovator_200,
  Dedicated_50_shadow,
  Dedicated_50,
  Dedicated_200_shadow,
  Dedicated_200,
  Curiosity_50_shadow,
  Curiosity_50,
  Curiosity_200_shadow,
  Curiosity_200,
  Motivated_50_shadow,
  Motivated_50,
  Motivated_200_shadow,
  Motivated_200,
  Capable_50,
  Capable_50_shadow,
  Capable_200,
  Capable_200_shadow,
  Endurance_50_shadow,
  Endurance_50,
  Endurance_200_shadow,
  Endurance_200,
  Driven_50_shadow,
  Driven_50,
  Driven_200_shadow,
  Driven_200,
  Engaged_50_shadow,
  Engaged_50,
  Engaged_200_shadow,
  Engaged_200,
  Resolute_50_shadow,
  Resolute_50,
  Resolute_200_shadow,
  Resolute_200,
  Empowered_50_shadow,
  Empowered_50,
  Empowered_200_shadow,
  Empowered_200,
  Accomplished_50_shadow,
  Accomplished_50,
  Accomplished_200_shadow,
  Accomplished_200,

  // gold badges
  Pioneer_200,
  Pioneer_50,
  Pioneer_200_shadow,
  Pioneer_50_shadow,
  Fearless_200,
  Fearless_50,
  Fearless_200_shadow,
  Fearless_50_shadow,
  Adventurer_200,
  Adventurer_50,
  Adventurer_200_shadow,
  Adventurer_50_shadow,
  Adaptable_200,
  Adaptable_50,
  Adaptable_200_shadow,
  Adaptable_50_shadow,
  Energized_200,
  Energized_50,
  Energized_200_shadow,
  Energized_50_shadow,
  Resourceful_200,
  Resourceful_50,
  Resourceful_200_shadow,
  Resourceful_50_shadow,
  Persistent_200,
  Persistent_50,
  Persistent_200_shadow,
  Persistent_50_shadow,
  Resilient_200,
  Resilient_50,
  Resilient_200_shadow,
  Resilient_50_shadow,
  Receptive_200,
  Receptive_50,
  Receptive_200_shadow,
  Receptive_50_shadow,
  Transformed_200,
  Transformed_50,
  Transformed_200_shadow,
  Transformed_50_shadow,
  Inspiring_200,
  Inspiring_50,
  Inspiring_200_shadow,
  Inspiring_50_shadow,
  Reflective_200,
  Reflective_50,
  Reflective_200_shadow,
  Reflective_50_shadow,

  //platinum badges
  Ready_200,
  Ready_50,
  Ready_200_shadow,
  Ready_50_shadow,
  Responsive_200,
  Responsive_50,
  Responsive_200_shadow,
  Responsive_50_shadow,
  Ownership_200,
  Ownership_50,
  Ownership_200_shadow,
  Ownership_50_shadow,
  Open_200,
  Open_50,
  Open_200_shadow,
  Open_50_shadow,
  Willing_200,
  Willing_50,
  Willing_200_shadow,
  Willing_50_shadow,
  Striving_200,
  Striving_50,
  Striving_200_shadow,
  Striving_50_shadow,
  Tough_200,
  Tough_50,
  Tough_200_shadow,
  Tough_50_shadow,
  Thoughtful_200,
  Thoughtful_50,
  Thoughtful_200_shadow,
  Thoughtful_50_shadow,
  Thinker_200,
  Thinker_50,
  Thinker_200_shadow,
  Thinker_50_shadow,
  Humble_200,
  Humble_50,
  Humble_200_shadow,
  Humble_50_shadow,
  Skilled_200,
  Skilled_50,
  Skilled_200_shadow,
  Skilled_50_shadow,
  Activated_200,
  Activated_50,
  Activated_200_shadow,
  Activated_50_shadow,


  Evolve_200,
  Evolve_50,
  Evolve_200_shadow,
  Evolve_50_shadow,
  Focused_200,
  Focused_50,
  Focused_200_shadow,
  Focused_50_shadow,
  Veteran_200,
  Veteran_50,
  Veteran_200_shadow,
  Veteran_50_shadow,
  Leader_200,
  Leader_50,
  Leader_200_shadow,
  Leader_50_shadow,
  Devoted_200,
  Devoted_50,
  Devoted_200_shadow,
  Devoted_50_shadow,
  Consistent_200,
  Consistent_50,
  Consistent_200_shadow,
  Consistent_50_shadow,
  Steadfast_200,
  Steadfast_50,
  Steadfast_200_shadow,
  Steadfast_50_shadow,
  Visionary_200,
  Visionary_50,
  Visionary_200_shadow,
  Visionary_50_shadow,
  Proficient_200,
  Proficient_50,
  Proficient_200_shadow,
  Proficient_50_shadow,
  Mindful_200,
  Mindful_50,
  Mindful_200_shadow,
  Mindful_50_shadow,
  Insightful_200,
  Insightful_50,
  Insightful_200_shadow,
  Insightful_50_shadow,
  Dynamic_200,
  Dynamic_50,
  Dynamic_200_shadow,
  Dynamic_50_shadow
} from "../../serverVariables/badges";

const Badge = (props) => {
  const { badge, size, shadow } = props;

  function chooseBadge(size, shadow) {
    let lower;
    if (typeof badge === "number") lower = badge;
    if (typeof badge === "string") lower = badge.toLowerCase();
    if (
      lower === BADGES.TRAILHEAD ||
      lower === BADGES.TRAILBLAZER ||
      lower == BADGE_IDS.TRAILBLAZER
    ) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Trailblazer_50_shadow : Trailblazer_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Trailblazer_200_shadow : Trailblazer_200}
          alt=""
          
        />
      );
    } else if (lower === BADGES.COURAGEOUS || lower == BADGE_IDS.COURAGEOUS) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Courageous_50_shadow : Courageous_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Courageous_200_shadow : Courageous_200}
          alt=""
        />
      );
    } else if (lower === BADGES.EXPLORER || lower == BADGE_IDS.EXPLORER) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Explorer_50_shadow : Explorer_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Explorer_200_shadow : Explorer_200}
          alt=""
        />
      );
    } else if (lower === BADGES.ACCEPTING || lower == BADGE_IDS.ACCEPTING) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Accepting_50_shadow : Accepting_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Accepting_200_shadow : Accepting_200}
          alt=""
        />
      );
    } else if (
      lower === BADGES.ENTHUSIASTIC ||
      lower == BADGE_IDS.ENTHUSIASTIC
    ) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Enthusiastic_50_shadow : Enthusiastic_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Enthusiastic_200_shadow : Enthusiastic_200}
          alt=""
        />
      );
    } else if (lower === BADGES.PROACTIVE || lower == BADGE_IDS.PROACTIVE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Proactive_50_shadow : Proactive_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Proactive_200_shadow : Proactive_200}
          alt=""
        />
      );
    } else if (lower === BADGES.CREATIVE || lower == BADGE_IDS.CREATIVE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Creative_50_shadow : Creative_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Creative_200_shadow : Creative_200}
          alt=""
        />
      );
    } else if (
      lower === BADGES.PERSEVERANCE ||
      lower == BADGE_IDS.PERSEVERANCE
    ) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Perseverance_50_shadow : Perseverance_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Perseverance_200_shadow : Perseverance_200}
          alt=""
        />
      );
    } else if (lower === BADGES.TENACITY || lower == BADGE_IDS.TENACITY) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Tenacity_50_shadow : Tenacity_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Tenacity_200_shadow : Tenacity_200}
          alt=""
        />
      );
    } else if (lower === BADGES.DETERMINED || lower == BADGE_IDS.DETERMINED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Determined_50_shadow : Determined_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Determined_200_shadow : Determined_200}
          alt=""
        />
      );
    } else if (lower === BADGES.COMMITTED || lower == BADGE_IDS.COMMITTED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Committed_50_shadow : Committed_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Committed_200_shadow : Committed_200}
          alt=""
        />
      );
    } else if (lower === BADGES.DEPENDABLE || lower == BADGE_IDS.DEPENDABLE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Dependable_50_shadow : Dependable_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Dependable_200_shadow : Dependable_200}
          alt=""
        />
      );
    } else if (lower === BADGES.GRIT || lower == BADGE_IDS.GRIT) {
      if (size === 50)
        return (
          <img {...props} src={shadow ? Grit_50_shadow : Grit_50} alt=""   width={50}
          height={50} />
        );
      return (
        <img {...props} src={shadow ? Grit_200_shadow : Grit_200} alt="" />
      );
    } else if (lower === BADGES.BOLD || lower == BADGE_IDS.BOLD) {
      if (size === 50)
        return (
          <img {...props} src={shadow ? Bold_50_shadow : Bold_50} alt=""    width={50}
          height={50}/>
        );
      return (
        <img {...props} src={shadow ? Bold_200_shadow : Bold_200} alt="" />
      );
    } else if (lower === BADGES.INNOVATOR || lower == BADGE_IDS.INNOVATOR) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Innovator_50_shadow : Innovator_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Innovator_200_shadow : Innovator_200}
          alt=""
        />
      );
    } else if (lower === BADGES.DEDICATED || lower == BADGE_IDS.DEDICATED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Dedicated_50_shadow : Dedicated_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Dedicated_200_shadow : Dedicated_200}
          alt=""
        />
      );
    } else if (lower === BADGES.CURIOSITY || lower == BADGE_IDS.CURIOSITY) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Curiosity_50_shadow : Curiosity_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Curiosity_200_shadow : Curiosity_200}
          alt=""
        />
      );
    } else if (lower === BADGES.MOTIVATED || lower == BADGE_IDS.MOTIVATED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Motivated_50_shadow : Motivated_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Motivated_200_shadow : Motivated_200}
          alt=""
        />
      );
    } else if (lower === BADGES.CAPABLE || lower == BADGE_IDS.CAPABLE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Capable_50_shadow : Capable_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Capable_200_shadow : Capable_200}
          alt=""
        />
      );
    } else if (lower === BADGES.ENDURANCE || lower == BADGE_IDS.ENDURANCE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Endurance_50_shadow : Endurance_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Endurance_200_shadow : Endurance_200}
          alt=""
        />
      );
    } else if (lower === BADGES.DRIVEN || lower == BADGE_IDS.DRIVEN) {
      if (size === 50)
        return (
          <img {...props} src={shadow ? Driven_50_shadow : Driven_50} alt=""    width={50}
          height={50}/>
        );
      return (
        <img {...props} src={shadow ? Driven_200_shadow : Driven_200} alt="" />
      );
    } else if (lower === BADGES.ENGAGED || lower == BADGE_IDS.ENGAGED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Engaged_50_shadow : Engaged_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Engaged_200_shadow : Engaged_200}
          alt=""
        />
      );
    } else if (lower === BADGES.RESOLUTE || lower == BADGE_IDS.RESOLUTE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Resolute_50_shadow : Resolute_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Resolute_200_shadow : Resolute_200}
          alt=""
        />
      );
    } else if (lower === BADGES.EMPOWERED || lower == BADGE_IDS.EMPOWERED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Empowered_50_shadow : Empowered_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Empowered_200_shadow : Empowered_200}
          alt=""
        />
      );
    } else if (
      lower === BADGES.ACCOMPLISHED ||
      lower == BADGE_IDS.ACCOMPLISHED
    ) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Accomplished_50_shadow : Accomplished_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Accomplished_200_shadow : Accomplished_200}
          alt=""
        />
      );
    }

    // Gold badges
    else if (lower === BADGES.PIONEER || lower == BADGE_IDS.PIONEER) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Pioneer_50_shadow : Pioneer_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Pioneer_200_shadow : Pioneer_200}
          alt=""
        />
      );
    } else if (lower === BADGES.FEARLESS || lower == BADGE_IDS.FEARLESS) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Fearless_50_shadow : Fearless_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Fearless_200_shadow : Fearless_200}
          alt=""
        />
      );
    } else if (lower === BADGES.ADVENTURER || lower == BADGE_IDS.ADVENTURER) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Adventurer_50_shadow : Adventurer_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Adventurer_200_shadow : Adventurer_200}
          alt=""
        />
      );
    } else if (lower === BADGES.ADAPTABLE || lower == BADGE_IDS.ADAPTABLE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Adaptable_50_shadow : Adaptable_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Adaptable_200_shadow : Adaptable_200}
          alt=""
        />
      );
    } else if (lower === BADGES.ENERGIZED || lower == BADGE_IDS.ENERGIZED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Energized_50_shadow : Energized_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Energized_200_shadow : Energized_200}
          alt=""
          
        />
      );
    } else if (lower === BADGES.RESOURCEFUL || lower == BADGE_IDS.RESOURCEFUL) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Resourceful_50_shadow : Resourceful_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Resourceful_200_shadow : Resourceful_200}
          alt=""
        />
      );
    } else if (lower === BADGES.PERSISTENT || lower == BADGE_IDS.PERSISTENT) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Persistent_50_shadow : Persistent_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Persistent_200_shadow : Persistent_200}
          alt=""
        />
      );
    } else if (lower === BADGES.RESILIENT || lower == BADGE_IDS.RESILIENT) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Resilient_50_shadow : Resilient_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Resilient_200_shadow : Resilient_200}
          alt=""
        />
      );
    } else if (lower === BADGES.RECEPTIVE || lower == BADGE_IDS.RECEPTIVE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Receptive_50_shadow : Receptive_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Receptive_200_shadow : Receptive_200}
          alt=""
        />
      );
    } else if (lower === BADGES.TRANSFORMED || lower == BADGE_IDS.TRANSFORMED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Transformed_50_shadow : Transformed_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Transformed_200_shadow : Transformed_200}
          alt=""
        />
      );
    } else if (lower === BADGES.INSPIRING || lower == BADGE_IDS.INSPIRING) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Inspiring_50_shadow : Inspiring_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Inspiring_200_shadow : Inspiring_200}
          alt=""
        />
      );
    } else if (lower === BADGES.REFLECTIVE || lower == BADGE_IDS.REFLECTIVE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Reflective_50_shadow : Reflective_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Reflective_200_shadow : Reflective_200}
          alt=""
        />
      );
    }

    else if (lower === BADGES.READY || lower == BADGE_IDS.READY) {
      if (size === 50)
        return (
          <img {...props} src={shadow ? Ready_50_shadow : Ready_50} alt=""    width={50}
          height={50}/>
        );
      return (
        <img {...props} src={shadow ? Ready_200_shadow : Ready_200} alt="" />
      );
    } else if (lower === BADGES.RESPONSIVE || lower == BADGE_IDS.RESPONSIVE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Responsive_50_shadow : Responsive_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Responsive_200_shadow : Responsive_200}
          alt=""
        />
      );
    } else if (lower === BADGES.OWNERSHIP || lower == BADGE_IDS.OWNERSHIP) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Ownership_50_shadow : Ownership_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Ownership_200_shadow : Ownership_200}
          alt=""
        />
      );
    } else if (lower === BADGES.OPEN || lower == BADGE_IDS.OPEN) {
      if (size === 50)
        return (
          <img {...props} src={shadow ? Open_50_shadow : Open_50} alt=""    width={50}
          height={50}/>
        );
      return (
        <img {...props} src={shadow ? Open_200_shadow : Open_200} alt="" />
      );
    } else if (lower === BADGES.WILLING || lower == BADGE_IDS.WILLING) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Willing_50_shadow : Willing_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Willing_200_shadow : Willing_200}
          alt=""
        />
      );
    } else if (lower === BADGES.STRIVING || lower == BADGE_IDS.STRIVING) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Striving_50_shadow : Striving_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Striving_200_shadow : Striving_200}
          alt=""
        />
      );
    } else if (lower === BADGES.TOUGH || lower == BADGE_IDS.TOUGH) {
      if (size === 50)
        return (
          <img {...props} src={shadow ? Tough_50_shadow : Tough_50} alt=""   width={50}
          height={50} />
        );
      return (
        <img {...props} src={shadow ? Tough_200_shadow : Tough_200} alt="" />
      );
    } else if (lower === BADGES.THOUGHTFUL || lower == BADGE_IDS.THOUGHTFUL) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Thoughtful_50_shadow : Thoughtful_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Thoughtful_200_shadow : Thoughtful_200}
          alt=""
        />
      );
    } else if (lower === BADGES.THINKER || lower == BADGE_IDS.THINKER) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Thinker_50_shadow : Thinker_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Thinker_200_shadow : Thinker_200}
          alt=""
        />
      );
    } else if (lower === BADGES.HUMBLE || lower == BADGE_IDS.HUMBLE) {
      if (size === 50)
        return (
          <img {...props} src={shadow ? Humble_50_shadow : Humble_50} alt=""   width={50}
          height={50} />
        );
      return (
        <img {...props} src={shadow ? Humble_200_shadow : Humble_200} alt="" />
      );
    } else if (lower === BADGES.SKILLED || lower == BADGE_IDS.SKILLED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Skilled_50_shadow : Skilled_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Skilled_200_shadow : Skilled_200}
          alt=""
        />
      );
    } else if (lower === BADGES.ACTIVATED || lower == BADGE_IDS.ACTIVATED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Activated_50_shadow : Activated_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Activated_200_shadow : Activated_200}
          alt=""
        />
      );
    }

     else if (lower === BADGES.EVOLVE || lower == BADGE_IDS.EVOLVE) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Evolve_50_shadow : Evolve_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Evolve_200_shadow : Evolve_200}
          alt=""
        />
      );
    } else if (lower === BADGES.FOCUSED || lower == BADGE_IDS.FOCUSED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Focused_50_shadow : Focused_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Focused_200_shadow : Focused_200}
          alt=""
        />
      );
    } else if (lower === BADGES.VETERAN || lower == BADGE_IDS.VETERAN) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Veteran_50_shadow : Veteran_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Veteran_200_shadow : Veteran_200}
          alt=""
        />
      );
    } else if (lower === BADGES.LEADER || lower == BADGE_IDS.LEADER) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Leader_50_shadow : Leader_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Leader_200_shadow : Leader_200}
          alt=""
        />
      );
    } else if (lower === BADGES.DEVOTED || lower == BADGE_IDS.DEVOTED) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Devoted_50_shadow : Devoted_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Devoted_200_shadow : Devoted_200}
          alt=""
        />
      );
    } else if (lower === BADGES.CONSISTENT || lower == BADGE_IDS.CONSISTENT) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Consistent_50_shadow : Consistent_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Consistent_200_shadow : Consistent_200}
          alt=""
        />
      );
    } else if (lower === BADGES.STEADFAST || lower == BADGE_IDS.STEADFAST) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Steadfast_50_shadow : Steadfast_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Steadfast_200_shadow : Steadfast_200}
          alt=""
        />
      );
    } else if (lower === BADGES.VISIONARY || lower == BADGE_IDS.VISIONARY) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Visionary_50_shadow : Visionary_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Visionary_200_shadow : Visionary_200}
          alt=""
        />
      );
    } else if (lower === BADGES.PROFICIENT || lower == BADGE_IDS.PROFICIENT) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Proficient_50_shadow : Proficient_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Proficient_200_shadow : Proficient_200}
          alt=""
        />
      );
    } else if (lower === BADGES.MINDFUL || lower == BADGE_IDS.MINDFUL) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Mindful_50_shadow : Mindful_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Mindful_200_shadow : Mindful_200}
          alt=""
        />
      );
    } else if (lower === BADGES.INSIGHTFUL || lower == BADGE_IDS.INSIGHTFUL) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Insightful_50_shadow : Insightful_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Insightful_200_shadow : Insightful_200}
          alt=""
        />
      );
    } else if (lower === BADGES.DYNAMIC || lower == BADGE_IDS.DYNAMIC) {
      if (size === 50)
        return (
          <img
            {...props}
            src={shadow ? Dynamic_50_shadow : Dynamic_50}
            alt=""
            width={50}
            height={50}
          />
        );
      return (
        <img
          {...props}
          src={shadow ? Dynamic_200_shadow : Dynamic_200}
          alt=""
        />
      );
    }

    if (size === 50)
      return (
        <img
          {...props}
          src={shadow ? Trailblazer_50_shadow : Trailblazer_50}
          alt=""
        />
      );
    return (
      <img
        {...props}
        src={shadow ? Trailblazer_200_shadow : Trailblazer_200}
        alt=""
      />
    );
  }

  return chooseBadge(size, shadow);
};

export default Badge;
