import React, { useState, useCallback, useMemo, useEffect, memo } from "react";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import PortalCard from "../portalCard/PortalCard";
import CustomTooltip from "../portalDashboard/components/CustomTooltip";
import styles from "./PortalContentChart.module.css";


// ---------------------------------------
// Helper: Format Keys (remove underscores, capitalize first letter)
// ---------------------------------------
const formatKey = (key) => {
  if (!key) return "";
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// ---------------------------------------
// Tabs configuration
// ---------------------------------------
const TABS = [
  { label: "Views", key: "views" },
  { label: "Watch Time", key: "watchTime" },
  { label: "Avg. Watch Time", key: "averageWatchTime" },
];

// ---------------------------------------
// Main Component
// ---------------------------------------
const PortalContentChart = ({
  contentViewsData,
  contentWatchtimeData,
  contentAverageWatchtimeData,
  categoryViewsData,
  categoryWatchtimeData,
  categoryAverageWatchtimeData,
}) => {
  const [selectedTab, setSelectedTab] = useState("views");
  const [selectedCategory, setSelectedCategory] = useState("content");
  // Initially, if there is category data, all keys (except date) are selected.
  // Preselect the first two keys (ignoring "date")
  const [selectedCategoryKeys, setSelectedCategoryKeys] = useState(() => {
    if (categoryViewsData && categoryViewsData.length > 0) {
      const keys = Object.keys(categoryViewsData[0]).filter(
        (key) => key !== "date"
      );
      return new Set(keys.slice(0, 2)); // preselect first 2 keys
    }
    return new Set();
  });


  const handleCategoryToggle = useCallback(
    (category) => {
      setSelectedCategory(category);
      if (
        category === "category" &&
        categoryViewsData &&
        categoryViewsData.length > 0
      ) {
        const keys = Object.keys(categoryViewsData[0]).filter(
          (key) => key !== "date"
        );
        setSelectedCategoryKeys(new Set(keys.slice(0, 2))); // preselect first 2 keys on toggle
      }
    },
    [categoryViewsData]
  );

  // State for animating the chart container
  const [chartVisible, setChartVisible] = useState(true);

  // When selectedCategory changes, trigger fade-out/fade-in
  useEffect(() => {
    setChartVisible(false);
    const timeout = setTimeout(() => setChartVisible(true), 300);
    return () => clearTimeout(timeout);
  }, [selectedCategory]);

  const handleTabClick = useCallback((key) => {
    setSelectedTab(key);
  }, []);

  const toggleCategoryKey = useCallback((key) => {
    setSelectedCategoryKeys((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      return newSet;
    });
  }, []);

  // Process data: Convert string values to numbers.
  const processData = (data) =>
    data.map((item) => {
      const keys = Object.keys(item).filter((key) => key !== "date");
      const converted = { date: item.date };
      keys.forEach((key) => {
        let raw = item[key];
        if (typeof raw === "string") {
          raw = raw.trim();
        }
        const num = Number(raw);
        converted[key] = isNaN(num) ? 0 : num;
        converted[`${key}_sec`] = isNaN(num) ? 0 : num;
      });
      return converted;
    });

  const chartData = useMemo(() => {
    if (selectedCategory === "content") {
      if (
        selectedTab === "views" &&
        contentViewsData &&
        contentViewsData.length > 0
      ) {
        return contentViewsData;
      }
      if (
        selectedTab === "watchTime" &&
        contentWatchtimeData &&
        contentWatchtimeData.length > 0
      ) {
        return contentWatchtimeData;
      }
      if (
        selectedTab === "averageWatchTime" &&
        contentAverageWatchtimeData &&
        contentAverageWatchtimeData.length > 0
      ) {
        return contentAverageWatchtimeData;
      }
    } else if (selectedCategory === "category") {
      if (
        selectedTab === "views" &&
        categoryViewsData &&
        categoryViewsData.length > 0
      ) {
        return processData(categoryViewsData);
      }
      if (
        selectedTab === "watchTime" &&
        categoryWatchtimeData &&
        categoryWatchtimeData.length > 0
      ) {
        return processData(categoryWatchtimeData);
      }
      if (
        selectedTab === "averageWatchTime" &&
        categoryAverageWatchtimeData &&
        categoryAverageWatchtimeData.length > 0
      ) {
        return processData(categoryAverageWatchtimeData);
      }
    }
    return [];
  }, [
    selectedTab,
    selectedCategory,
    contentViewsData,
    contentWatchtimeData,
    contentAverageWatchtimeData,
    categoryViewsData,
    categoryWatchtimeData,
    categoryAverageWatchtimeData,
  ]);

  const dynamicDataKey = useMemo(() => {
    if (selectedCategory === "category") return null;
    if (selectedTab === "views") return "views";
    if (selectedTab === "watchTime") return "total_duration";
    if (selectedTab === "averageWatchTime") return "average_duration";
    return "value";
  }, [selectedTab, selectedCategory]);

  // For drawing areas, only use keys that are selected.
  const categoryKeys = useMemo(() => {
    if (selectedCategory === "category") {
      let data = [];
      if (
        selectedTab === "views" &&
        categoryViewsData &&
        categoryViewsData.length > 0
      ) {
        data = categoryViewsData;
      } else if (
        selectedTab === "watchTime" &&
        categoryWatchtimeData &&
        categoryWatchtimeData.length > 0
      ) {
        data = categoryWatchtimeData;
      } else if (
        selectedTab === "averageWatchTime" &&
        categoryAverageWatchtimeData &&
        categoryAverageWatchtimeData.length > 0
      ) {
        data = categoryAverageWatchtimeData;
        console.log(data);
      }
      if (data.length > 0) {
        const keys = Object.keys(data[0]).filter((key) => key !== "date");
        return keys.filter((key) => selectedCategoryKeys.has(key));
      }
    }
    return [];
  }, [
    selectedCategory,
    selectedTab,
    categoryViewsData,
    categoryWatchtimeData,
    categoryAverageWatchtimeData,
    selectedCategoryKeys,
  ]);

  // Compute all category keys for the table.
  const allCategoryKeys = useMemo(() => {
    let data = [];
    if (
      selectedTab === "views" &&
      categoryViewsData &&
      categoryViewsData.length > 0
    ) {
      data = categoryViewsData;
    } else if (
      selectedTab === "watchTime" &&
      categoryWatchtimeData &&
      categoryWatchtimeData.length > 0
    ) {
      data = categoryWatchtimeData;
    } else if (
      selectedTab === "averageWatchTime" &&
      categoryAverageWatchtimeData &&
      categoryAverageWatchtimeData.length > 0
    ) {
      data = categoryAverageWatchtimeData;
    }
    if (data.length > 0) {
      return Object.keys(data[0]).filter((key) => key !== "date");
    }
    return [];
  }, [
    selectedTab,
    categoryViewsData,
    categoryWatchtimeData,
    categoryAverageWatchtimeData,
  ]);

  // Define your color palette.
  const colorPalette = ["#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#8dd1e1"];

  // Create a mapping from key to color.
  const categoryColorMapping = useMemo(() => {
    let mapping = {};
    const keys = Array.isArray(categoryKeys) ? categoryKeys : [];
    keys.forEach((key, index) => {
      mapping[key] = colorPalette[index % colorPalette.length];
    });
    return mapping;
  }, [categoryKeys, colorPalette]);

  const yAxisTickFormatter = (value) => {
    if (selectedTab === "views") {
      return value; // Return raw number for views
    } else {
      // For watch time/average watch time, format as seconds/minutes.
      if (value < 60) {
        return value + " sec";
      } else {
        return (value / 60).toFixed(1) + " min";
      }
    }
  };

  const aggregatedViews = useMemo(() => {
    let result = {};
    if (
      selectedCategory === "category" &&
      categoryViewsData &&
      categoryViewsData.length > 0
    ) {
      // Get the keys from the first row, excluding 'date'
      const keys = Object.keys(categoryViewsData[0]).filter(
        (key) => key !== "date"
      );
      keys.forEach((key) => {
        const normalizedKey = key.trim().toLowerCase();
        result[normalizedKey] = categoryViewsData.reduce((acc, row) => {
          const val = Number(row[key]) || 0;
          return acc + val;
        }, 0);
      });
    }
    return result;
  }, [selectedCategory, categoryViewsData]);

  // Compute maximum value from chartData.
  const maxVal = useMemo(() => {
    if (chartData.length === 0) return 0;
    if (selectedCategory === "content") {
      return Math.max.apply(
        null,
        chartData.map((item) =>
          item[dynamicDataKey] ? item[dynamicDataKey] : 0
        )
      );
    } else {
      let maxV = 0;
      chartData.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== "date" && item[key] > maxV) {
            maxV = item[key];
          }
        });
      });
      return maxV;
    }
  }, [chartData, selectedCategory, dynamicDataKey]);

  // Compute YAxis domain.
  const yAxisDomain = useMemo(() => {
    const paddedMax = Math.ceil(maxVal * 1.2);
    return [0, paddedMax];
  }, [maxVal]);

  return (
    <PortalCard className={styles.container}>
      {/* Tabs */}
      <div className={styles.tabs}>
        {TABS.map(({ label, key }) => (
          <div
            key={key}
            className={`${styles.tab} ${
              selectedTab === key ? styles.activeTab : ""
            }`}
            onClick={() => handleTabClick(key)}
          >
            {label}
          </div>
        ))}
      </div>

      {/* Toggle Buttons */}
      <div className={styles.toggleContainer}>
        <button
          className={`${styles.toggleButton} ${
            selectedCategory === "content"
              ? styles.toggleActive
              : styles.toggleInactive
          }`}
          onClick={() => handleCategoryToggle("content")}
        >
          Content
        </button>
        <button
          className={`${styles.toggleButton} ${
            selectedCategory === "category"
              ? styles.toggleActive
              : styles.toggleInactive
          }`}
          onClick={() => handleCategoryToggle("category")}
        >
          Category
        </button>
      </div>

      {/* Chart Wrapper with fade transition */}
      <div
        className={`${styles.chartWrapper} ${
          chartVisible ? styles.visible : ""
        }`}
      >
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={chartData}>
            <defs>
              {/* Content gradients */}
              <linearGradient id="auroraGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#5b9bd5" stopOpacity={1} />
                <stop offset="50%" stopColor="#4178a8" stopOpacity={0.4} />
                <stop offset="100%" stopColor="#4178a8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="underGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#5b9bd5" />
                <stop offset="100%" stopColor="#4178a8" />
              </linearGradient>
              {/* Category gradients */}
              {selectedCategory === "category" &&
                categoryKeys.map((key, index) => (
                  <linearGradient
                    key={index}
                    id={`categoryGradient-${index}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="0%"
                      stopColor={colorPalette[index % colorPalette.length]}
                      stopOpacity={1}
                    />
                    <stop
                      offset="50%"
                      stopColor={colorPalette[index % colorPalette.length]}
                      stopOpacity={0.4}
                    />
                    <stop
                      offset="100%"
                      stopColor={colorPalette[index % colorPalette.length]}
                      stopOpacity={0}
                    />
                  </linearGradient>
                ))}
            </defs>
            <CartesianGrid strokeDasharray="1" />
            <XAxis
              dataKey="date"
              tick={{ fill: "#777", fontSize: 14, fontWeight: 400 }}
            />
            <YAxis
              tickFormatter={yAxisTickFormatter}
              domain={yAxisDomain}
              tick={{ fill: "#777", fontSize: 14, fontWeight: 400 }}
            />
            {/* Pass dynamic prop based on selected category */}
            <Tooltip
              content={
                <CustomTooltip
                  dynamic={selectedCategory === "content"}
                  selectedTab={selectedTab}
                />
              }
            />

            {/* Format legend labels */}
            {selectedCategory === "category" ? (
              categoryKeys.map((key, index) => (
                <Area
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={colorPalette[index % colorPalette.length]}
                  strokeWidth={1}
                  fill={`url(#categoryGradient-${index})`}
                  dot
                />
              ))
            ) : (
              <Area
                type="monotone"
                dataKey={dynamicDataKey}
                stroke="url(#underGradient)"
                fill="url(#auroraGradient)"
                dot
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>

      {/* Table for Category Keys */}
      {selectedCategory === "category" && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={`${styles.th} ${styles.checkboxColumn}`}>
                  Select
                </th>
                <th className={styles.th}>Category</th>
                <th className={styles.th}>Views</th>
              </tr>
            </thead>
            <tbody>
              {allCategoryKeys.map((key) => (
                <tr
                  key={key}
                  className={styles.tableRow}
                  onClick={() => toggleCategoryKey(key)}
                  style={
                    selectedCategoryKeys.has(key)
                      ? { backgroundColor: categoryColorMapping[key] + "12" } // adds semi-transparent highlight if selected
                      : {}
                  }
                >
                  <td className={`${styles.td} ${styles.checkboxColumn}`}>
                    <input
                      type="checkbox"
                      checked={selectedCategoryKeys.has(key)}
                      readOnly
                    />
                  </td>
                  <td className={styles.td}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          width: 12,
                          height: 12,
                          backgroundColor: categoryColorMapping[key],
                          marginRight: 8,
                          borderRadius: 2,
                        }}
                      />
                      {formatKey(key)}
                    </div>
                  </td>

                  <td className={styles.td}>
                    {aggregatedViews[key.toLowerCase()] || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </PortalCard>
  );
};

export default memo(PortalContentChart);