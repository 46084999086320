import React, { useEffect, useState, useMemo, useRef } from "react";
import useDebouncedEffect from "../utils/useDebouncedEffect";

// routes
import {
  fetchPortalCategories,
  fetchPortalContentList,
  fetchPortalCustomerCategoryAverageWatchtime,
  fetchPortalCustomerCategoryTotalViews,
  fetchPortalCustomerCategoryWatchtime,
  fetchPortalCustomerContentAverageWatchtime,
  fetchPortalCustomerContentTotalViews,
  fetchPortalCustomerContentTotalWatchtime,
} from "../routes/DashboardPortalRoutes";

// redux
import { portalApiMap } from "../../../store/actions/portalAction";
import { connect } from "react-redux";

// components
import PortalContentChart from "../../portalContentChart/PortalContentChart";
import PortalTopContent from "../../portalTopContent/portalTopContent";
import DateRange from "../components/DateRange";

const PortalContent = ({ portalApiMap, organizationId }) => {
  // Default date range: Last 30 days
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());

  const [contentViewsData, setContentViewsData] = useState([]);
  const [contentWatchtimeData, setContentWatchtimeData] = useState([]);
  const [contentAverageWatchtimeData, setContentAverageWatchtimeData] = useState([]);
  const [categoryViewsData, setCategoryViewsData] = useState([]);
  const [categoryWatchtimeData, setCategoryWatchtimeData] = useState([]);
  const [categoryAverageWatchtimeData, setCategoryAverageWatchtimeData] = useState([]);
  const [selectedRange, setSelectedRange] = useState("Last 30 Days");
  const [contentLoading, setContentLoading] = useState(false);

  // Default debounce delay for other effects:
  const debounceDelay = selectedRange === "Custom Range" ? 1200 : 0;

  const [portalCategories, setPortalCategories] = useState([]);
  const [contentPage, setContentPage] = useState(1);
  // New state for sort order
  const [contentSort, setContentSort] = useState("views-desc");
  // New state for search: contentSearch holds the text as typed,
  // and appliedSearch holds the text to be used in the API call.
  const [contentSearch, setContentSearch] = useState("");
  const [appliedSearch, setAppliedSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Categories");

  // New state for portal content list (for PortalTopContent)
  const [portalContent, setPortalContent] = useState({
    content: [],
    pages: 0,
    count: 0,
  });
  // Set limit of 5 items per page
  const contentItemsPerPage = 5;

  useEffect(() => {
    setContentPage(1);
    setContentSearch("");
    setAppliedSearch("");
    setSelectedCategory("All Categories");
    // Optionally, clear the data while new data is fetched
    setPortalContent({ content: [], pages: 0, count: 0 });
  }, [startDate, endDate, selectedRange]);
  

  // -------------------------
  // Other Debounced Effects (using debounceDelay)
  // -------------------------
  useDebouncedEffect(
    () => {
      (async () => {
        try {
          const [views, avgWatchtime] = await Promise.all([
            fetchPortalCustomerContentTotalViews(
              portalApiMap,
              startDate.toISOString(),
              endDate.toISOString()
            ),
            fetchPortalCustomerContentAverageWatchtime(
              portalApiMap,
              startDate.toISOString(),
              endDate.toISOString()
            ),
          ]);
          setContentViewsData(Array.isArray(views) ? views : []);
          setContentAverageWatchtimeData(
            Array.isArray(avgWatchtime) ? avgWatchtime : []
          );
        } catch (err) {
          console.error("Error fetching content views/average watchtime:", err);
        }
      })();
    },
    [selectedRange, startDate, endDate, portalApiMap],
    debounceDelay
  );

  // Group 3: Category Data (debounced)
  useDebouncedEffect(
    () => {
      (async () => {
        try {
          const [catViews, catWatchtime, catAvgWatchtime] = await Promise.all([
            fetchPortalCustomerCategoryTotalViews(
              portalApiMap,
              startDate.toISOString(),
              endDate.toISOString()
            ),
            fetchPortalCustomerCategoryWatchtime(
              portalApiMap,
              startDate.toISOString(),
              endDate.toISOString()
            ),
            fetchPortalCustomerCategoryAverageWatchtime(
              portalApiMap,
              startDate.toISOString(),
              endDate.toISOString()
            ),
          ]);
          setCategoryViewsData(Array.isArray(catViews) ? catViews : []);
          setCategoryWatchtimeData(
            Array.isArray(catWatchtime) ? catWatchtime : []
          );
          setCategoryAverageWatchtimeData(
            Array.isArray(catAvgWatchtime) ? catAvgWatchtime : []
          );
        } catch (err) {
          console.error("Error fetching category data:", err);
        }
      })();
    },
    [selectedRange, startDate, endDate, portalApiMap],
    debounceDelay
  );

  useDebouncedEffect(() => {
    (async () => {
      try {
        const data = await fetchPortalCustomerContentTotalWatchtime(
          portalApiMap,
          startDate.toISOString(),
          endDate.toISOString()
        );
        setContentWatchtimeData(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error("Error fetching content total watchtime:", err);
      }
    })();
  },     [selectedRange, startDate, endDate, portalApiMap],
    debounceDelay
  );

  useDebouncedEffect(() => {
    (async () => {
      try {
        if (!organizationId) {
          throw new Error("Organization ID is required");
        }
        const categories = await fetchPortalCategories(
          portalApiMap,
          organizationId
        );
        setPortalCategories(Array.isArray(categories) ? categories : []);
      } catch (err) {
        console.error("Error fetching portal categories:", err);
      }
    })();
  }, [portalApiMap, organizationId]);

  // -------------------------
  // For the portal content list, we want special debounce logic.
  // If the page changes, delay = 0. Otherwise, if selectedRange is "Custom Range", delay = 1200; else 0.
  // -------------------------
  const prevContentPageRef = useRef(contentPage);
  useEffect(() => {
    prevContentPageRef.current = contentPage;
  }, [contentPage]);

  const contentListDebounceDelay = useMemo(() => {
    if (prevContentPageRef.current !== contentPage) {
      return 0;
    }
    return selectedRange === "Custom Range" ? 1200 : 0;
  }, [selectedRange, contentPage]);

  useDebouncedEffect(
    () => {
      (async () => {
        try {
          setContentLoading(true);
          const params = {
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            organization_id: organizationId,
            page: contentPage,
            limit: contentItemsPerPage,
            sort: contentSort,
            ...(selectedCategory !== "All Categories" && {
              category: selectedCategory,
            }),
            ...(appliedSearch && { title: appliedSearch }),
          };
          const data = await fetchPortalContentList(portalApiMap, params);
          setPortalContent(data);
          setContentLoading(false);
        } catch (err) {
          console.error("Error fetching portal content list:", err);
          setContentLoading(false);
        }
      })();
    },
    [
      selectedRange,
      startDate,
      endDate,
      contentPage,
      contentSort,
      selectedCategory,
      portalApiMap,
      organizationId,
      appliedSearch,
    ],
    contentListDebounceDelay
  );

  return (
    <>
      <DateRange
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
      />
      <PortalContentChart
        contentViewsData={contentViewsData}
        contentWatchtimeData={contentWatchtimeData}
        contentAverageWatchtimeData={contentAverageWatchtimeData}
        categoryViewsData={categoryViewsData}
        categoryWatchtimeData={categoryWatchtimeData}
        categoryAverageWatchtimeData={categoryAverageWatchtimeData}
      />

      <PortalTopContent
        data={portalContent.content}
        onSortChange={setContentSort}
        currentSort={contentSort}
        categories={portalCategories}
        selectedCategory={selectedCategory}
        onCategoryChange={setSelectedCategory}
        searchText={contentSearch}
        onSearchChange={setContentSearch}
        onSearchSubmit={() => setAppliedSearch(contentSearch)}
        loading={contentLoading}
        // Pagination props
        currentPage={contentPage}
        totalPages={
          portalContent.pages ||
          Math.ceil(portalContent.count / contentItemsPerPage)
        }
        onPageChange={setContentPage}
      />
    </>
  );
};

const actions = { portalApiMap };

export default connect(null, actions)(PortalContent);