import React, { useEffect, useState, useRef } from "react";
import confetti from "canvas-confetti";

// styles
import styles from "./rankAnimation.module.css";

// components
import StrapIcon from "./strapIcon";
import { ReactComponent as GreenStar } from "../../images/icons/greenStar.svg";
import { RANKS_IDS } from "../../serverVariables/ranks";

const bronzeBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Rank%3DBronze.png';
const silverBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Rank%3DSilver.png';
const goldBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Rank%3DGold.png';
const platinumBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/progress/Rank%3DPlatinum.png';
const diamondBadge = 'https://treo.s3.us-east-2.amazonaws.com/assets/ranks/RankDiamond.png';

  
const RankAnimation = (props) => {
  // props
  const { rank, close } = props;
  // local
  const [localRank] = useState(rank); // capture the rank variable on first pass
  const timeouts = useRef([]);
  const [showBackground, setShowBackground] = useState(false);
  const [showMedalStrap, setShowMedalStrap] = useState(false);
  const [animateCongratulations, setAnimateCongratulations] = useState(false);
  const [animateGlow, setAnimateGlow] = useState(false);
  const [animateStars, setAnimateStars] = useState(false);
  const [animateStarsTwo, setAnimateStarsTwo] = useState(false);
  const [showContinue, setShowContinue] = useState(false);

  // medal refs
  const bronze = useRef();
  const silver = useRef();
  const gold = useRef();
  const diamond = useRef();
  const platinum = useRef();

  const strap = useRef();

  // medal classes
  const bronzeClasses = useRef([]);
  const silverClasses = useRef([]);
  const goldClasses = useRef([]);
  const diamondClasses = useRef([]);
  const platinumClasses = useRef([]);

  // kick off the animation and clear any timeouts before unmounting
  useEffect(() => {
    startAnimation();
    return () => {
      timeouts.current.forEach((val) => clearTimeout(val));
    };
  }, []);

  // transition the container in
  useEffect(() => {
    setShowBackground(true);
  }, []);

  function startAnimation() {
    let time = 1;
    const t1 = setTimeout(() => {
      setShowMedalStrap(true);
    }, time * 1000);
    timeouts.current.push(t1);

    let newTime;
    if (localRank.id === RANKS_IDS.SILVER) newTime = silverAnimation(time);
    if (localRank.id === RANKS_IDS.GOLD) newTime = goldAnimation(time);
    if (localRank.id === RANKS_IDS.DIAMOND) newTime = diamondAnimation(time);
    if (localRank.id === RANKS_IDS.PLATINUM) newTime = platinumAnimation(time);

    generalAnimation(newTime);
  }

  function closeAnimation() {
    setShowBackground(false);
    setTimeout(() => close(), 1000);
  }

  function generalAnimation(time) {
    time += 0.7;
    const t5 = setTimeout(() => {
      setAnimateCongratulations(true);
    }, time * 1000);
    timeouts.current.push(t5);

    time += 0.2;
    const t6 = setTimeout(() => {
      setAnimateGlow(true);
    }, time * 1000);
    timeouts.current.push(t6);

    const t7 = setTimeout(() => {
      setAnimateStars(true);
    }, time * 1000);
    timeouts.current.push(t7);

    const t8 = setTimeout(() => {
      setAnimateStarsTwo(true);
    }, time * 1000);
    timeouts.current.push(t8);

    const t9 = setTimeout(() => {
      setShowContinue(true);
    }, time * 1000);
    timeouts.current.push(t9);

    return time;
  }

  function silverAnimation(time) {
    const { current: b } = bronze;
    const { current: s } = silver;

    time += 1;
    const t1 = setTimeout(() => {
      b.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t1);

    time += 1;
    const t2 = setTimeout(() => {
      b.classList.add(styles.bronzeMove);
    }, time * 1000);
    timeouts.current.push(t2);

    time += 0.7;
    const t3 = setTimeout(() => {
      s.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t3);

    time += 1;
    const t4 = setTimeout(() => {
      s.classList.add(styles.silverMove);
    }, time * 1000);
    timeouts.current.push(t4);

    return time;
  }

  function goldAnimation(time) {
    const { current: b } = bronze;
    const { current: s } = silver;
    const { current: g } = gold;

    time += 1;
    const t1 = setTimeout(() => {
      b.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t1);

    time += 1;
    const t2 = setTimeout(() => {
      b.classList.add(styles.bronzeMove_2);
    }, time * 1000);
    timeouts.current.push(t2);

    time += 0.7;
    const t3 = setTimeout(() => {
      s.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t3);

    time += 1;
    const t4 = setTimeout(() => {
      s.classList.add(styles.silverMove_2);
    }, time * 1000);
    timeouts.current.push(t4);

    time += 0.7;
    const t5 = setTimeout(() => {
      g.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t5);

    time += 1;
    const t6 = setTimeout(() => {
      g.classList.add(styles.goldMove);
    }, time * 1000);
    timeouts.current.push(t6);

    return time;
  }

  function diamondAnimation(time) {
    const { current: b } = bronze;
    const { current: s } = silver;
    const { current: g } = gold;
    const { current: p } = platinum;
    const { current: d } = diamond;
    const { current: strapEl } = strap;
  
    // Bronze: scale then move
    time += 1;
    const t1 = setTimeout(() => {
      b.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t1);
  
    time += 1;
    const t2 = setTimeout(() => {
      b.classList.add(styles.bronzeMove_4);
    }, time * 1000);
    timeouts.current.push(t2);
  
    // Silver: after 0.7 sec, shakeFade bronze & scale silver
    time += 0.7;
    const t3 = setTimeout(() => {
      b.classList.add(styles.shakeFade);
      s.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t3);
  
    // Silver: after 1 sec, silver move & shakeFade silver
    time += 1;
    const t4 = setTimeout(() => {
      s.classList.add(styles.silverMove_4);
      s.classList.add(styles.shakeFade);
    }, time * 1000);
    timeouts.current.push(t4);
  
    // Gold: after 0.7 sec, scale gold
    time += 0.7;
    const t5 = setTimeout(() => {
      g.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t5);
  
    // Gold: after 1 sec, gold move & shakeFade gold
    time += 1;
    const t6 = setTimeout(() => {
      g.classList.add(styles.goldMove_3);
      g.classList.add(styles.shakeFade);
    }, time * 1000);
    timeouts.current.push(t6);
  
    // Platinum: after 0.7 sec, scale platinum
    time += 0.7;
    const t7 = setTimeout(() => {
      p.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t7);
  
    // Platinum: after 1 sec, platinum move & shakeFade platinum; fade out strap
    time += 1;
    const t8 = setTimeout(() => {
      p.classList.add(styles.platinumMoveLeft);
      p.classList.add(styles.shakeFade);
      // Since the .strap CSS already has a transition on opacity, it will fade out smoothly.
      if (strap.current) {
        strap.current.style.opacity = "0";
      }
      setShowMedalStrap(false);
    }, time * 1000);
    timeouts.current.push(t8);
  
    // Diamond: after 0.7 sec, trigger diamond's flip animation and confetti
    time += 0.7;
    const t9 = setTimeout(() => {
      d.classList.add(styles.diamondMove);
      confetti({
        particleCount: 200,
        spread: 70,
        origin: { y: 0.6 },
      });
    }, time * 1000);
    timeouts.current.push(t9);
  
    return time;
  }
  

  function platinumAnimation(time) {
    const { current: b } = bronze;
    const { current: s } = silver;
    const { current: g } = gold;
    const { current: p } = platinum;

    time += 1;
    const t1 = setTimeout(() => {
      b.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t1);

    time += 1;
    const t2 = setTimeout(() => {
      b.classList.add(styles.bronzeMove_3);
    }, time * 1000);
    timeouts.current.push(t2);

    time += 0.7;
    const t3 = setTimeout(() => {
      s.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t3);

    time += 1;
    const t4 = setTimeout(() => {
      s.classList.add(styles.silverMove_3);
    }, time * 1000);
    timeouts.current.push(t4);

    time += 0.7;
    const t5 = setTimeout(() => {
      g.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t5);

    time += 1;
    const t6 = setTimeout(() => {
      g.classList.add(styles.goldMove_2);
    }, time * 1000);
    timeouts.current.push(t6);

    time += 0.7;
    const t7 = setTimeout(() => {
      p.classList.add(styles.scale);
    }, time * 1000);
    timeouts.current.push(t7);

    time += 1;
    const t8 = setTimeout(() => {
      p.classList.add(styles.platinumMove);
    }, time * 1000);
    timeouts.current.push(t8);

    return time;
  }

  return (
    <div className={`${styles.container} ${showBackground ? styles.show : ""}`}>
      <div className={`${styles.modal} ${showBackground ? styles.show : ""}`}>
        <GreenStar
          className={`${styles.greenStar} ${styles.left} ${
            animateStars ? styles.around : ""
          }`}
        />
        <GreenStar
          className={`${styles.greenStar} ${styles.right} ${
            animateStarsTwo ? styles.around : ""
          }`}
        />

{showMedalStrap ? <div  ref={strap}className={styles.strap}><StrapIcon/></div> : null}

        <h2
          className={`${styles.messageSecondary} ${styles.animateText} ${
            animateCongratulations ? styles.activeAnimation : ""
          }`}
        >
          Congratulations
        </h2>
        <h2
          className={`${styles.message} ${styles.animateText} ${
            animateCongratulations ? styles.activeAnimation : ""
          }`}
        >
          You've achieved a new rank!
        </h2>
        <h1 className={`${styles.rank} ${animateGlow ? styles.glowText : ""}`}>
          {localRank.name}
        </h1>

        <img
          ref={bronze}
          className={`${styles.medal} ${bronzeClasses.current.join(" ")}`}
          src={bronzeBadge}
          alt="bronze"
        />
        <img
          ref={silver}
          className={`${styles.medal} ${silverClasses.current.join(" ")}`}
          src={silverBadge}
          alt="silver"
        />
        <img
          ref={gold}
          className={`${styles.medal} ${goldClasses.current.join(" ")}`}
          src={goldBadge}
          alt="gold"
        />

        <img
          ref={platinum}
          className={`${styles.medal} ${platinumClasses.current.join(" ")} `}
          src={platinumBadge}
          alt="platinum"
        />
        <img
          ref={diamond}
          className={`${styles.medal} ${diamondClasses.current.join(" ")} ${
            styles.diamond
          }`}
          src={diamondBadge}
          alt="diamond"
        />

        <div className={styles.bottomArc}>
          <div className={styles.arc} />
          <button
            className={`${styles.continue} ${showContinue ? styles.show : ""}`}
            type="button"
            onClick={closeAnimation}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default RankAnimation;
