import React from 'react';
import { format, isAfter, isSameMonth, isSameYear } from 'date-fns';
import { VICTORY_TYPES } from '../../../serverVariables/victoryTypes';
import SelectVictoryImage from '../../selectVictoryImage/selectVictoryImage';
import styles from '../UserProgress.module.css';

// In-Progress Victory Component with Progress Bar
const InProgressVictory = ({ typeId, currentValue, threshold, size = 80 }) => {
  // Calculate progress percentage (capped at 100%)
  const progress = Math.min(Math.round((currentValue / threshold) * 100), 100);

  return (
    <div className={styles.victoryItem}> 
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className={styles.inProgressIconContainer} style={{ opacity: 0.7 }}> 
          <SelectVictoryImage
            typeId={typeId}
            victory={{ victory: { victory_type: { id: typeId } } }} 
            size={size}
            className={styles.victoryIcon}
          />
        </div>
        {/* Progress Bar */}
        <div className={styles.victoryProgressContainer} style={{ marginTop: '10px', width: '80%' }}>
          <div className={styles.victoryProgressBar}>
            <div 
              className={styles.progressFill} 
              style={{ width: `${progress}%` }} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const VictoryModal = ({ 
  isVisible, 
  onClose, 
  selectedDate, 
  monthlyVictories,
  monthlyVideosWatched, 
  currentStreak       
}) => {
  if (!isVisible) return null;

  const isCurrentMonthView = isSameMonth(selectedDate, new Date()) && isSameYear(selectedDate, new Date());

  return (
    <div className={styles.victoryModalContainer} onClick={onClose}>
      <div className={styles.victoryModalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <span className={styles.modalTitle}>{format(selectedDate, 'MMMM')} Victories</span>
          <button 
            className={styles.closeButton}
            onClick={onClose}
          >
            <i className="fas fa-times" style={{ fontSize: '14px', color: '#6c757d' }}></i>
          </button>
        </div>
        <div className={styles.victoryGrid}>
          {(() => {
            // group achieved victories by type to count them and check for existence
            const groupedVictories = monthlyVictories.reduce((groups, victory) => {
              const victoryTypeId = victory.victory.victory_type.id;
              if (!groups[victoryTypeId]) {
                groups[victoryTypeId] = {
                  type: victory.victory.victory_type,
                  victory: victory, 
                  count: 0
                };
              }
              groups[victoryTypeId].count++;
              return groups;
            }, {});

            const victoryComponents = [];

            // add achieved victories
            Object.values(groupedVictories).forEach((group) => {
              victoryComponents.push(
                <div key={`achieved-${group.type.id}`} className={styles.victoryItem}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SelectVictoryImage
                      typeId={group.type.id}
                      victory={group.victory}
                      size={80}
                      className={styles.victoryIcon}
                    />
                    <div className={styles.victoryCount} style={{ position: 'static', marginTop: '5px' }}>
                      <span className={styles.victoryCountText}>{`${group.count}x`}</span> 
                    </div>
                  </div>
                </div>
              );
            });

            // add in-progress victories (only for the current month view)
            if (isCurrentMonthView) {
              // In-progress video watching (8 videos)
              if (monthlyVideosWatched < 8 && !groupedVictories[VICTORY_TYPES.VIDEOS_WATCHED_8]) {
                victoryComponents.push(
                  <InProgressVictory
                    key="in-progress-videos-8"
                    typeId={VICTORY_TYPES.VIDEOS_WATCHED_8}
                    currentValue={monthlyVideosWatched}
                    threshold={8}
                  />
                );
              }
              // in-progress video watching (15 videos) - show only if 8 is achieved or passed
              if (monthlyVideosWatched >= 8 && monthlyVideosWatched < 15 && !groupedVictories[VICTORY_TYPES.VIDEOS_WATCHED_15]) {
                victoryComponents.push(
                  <InProgressVictory
                    key="in-progress-videos-15"
                    typeId={VICTORY_TYPES.VIDEOS_WATCHED_15}
                    currentValue={monthlyVideosWatched}
                    threshold={15}
                  />
                );
              }
              // show streak victory if we have a streak between 1-6 days and don't already have a streak victory
              if (currentStreak > 0 && currentStreak < 7 && !groupedVictories[VICTORY_TYPES.STREAK]) {
                victoryComponents.push(
                  <InProgressVictory
                    key="in-progress-streak"
                    typeId={VICTORY_TYPES.STREAK}
                    currentValue={currentStreak}
                    threshold={7}
                  />
                );
              }
            }

            // victory components or "no victories" message
            return victoryComponents.length > 0 ? victoryComponents : (
              <div className={styles.noVictoriesContainer}>
                <span className={styles.noVictoriesText}>No victories logged for {format(selectedDate, 'MMMM')}</span>
              </div>
            );
          })()}
        </div>
      </div>
    </div>
  );
};

export const MonthPickerModal = ({ 
  isVisible, 
  onClose, 
  selectedDate,
  onPrevMonth,
  onNextMonth
}) => {
  if (!isVisible) return null;

  return (
    <div className={styles.historyModalContainer} onClick={onClose}>
      <div className={styles.historyModalContent} onClick={e => e.stopPropagation()}>
        <button 
          className={styles.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" style={{ fontSize: '12px', color: '#6c757d' }}></i>
        </button>
        <div className={styles.monthPickerHeader}>
          <button 
            className={styles.monthNavButton}
            onClick={onPrevMonth}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="#6c757d">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/>
            </svg>
          </button>
          <span className={styles.monthPickerTitle}>
            {format(selectedDate, 'MMMM yyyy')}
          </span>
          <button 
            className={styles.monthNavButton}
            onClick={onNextMonth}
            disabled={isAfter(selectedDate, new Date())}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill={isAfter(selectedDate, new Date()) ? '#dbd8d8' : '#6c757d'}>
              <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};