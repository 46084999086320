import React, {useEffect, useState} from 'react';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// styles
import styles from './victoryTile.module.css';

// components
import {REACTION_TYPES} from "../../serverVariables/reactionTypes";
import {VICTORY_TYPES} from "../../serverVariables/victoryTypes";
import LikeReaction from "../community/likeReaction/likeReaction";
import WowReaction from "../community/wowReaction/wowReaction";
import EnvyReaction from "../community/envyReaction/envyReaction";
import LoveReaction from "../community/loveReaction/loveReaction";
import SelectVictoryImage from "../selectVictoryImage/selectVictoryImage";
import {RANKS_IDS} from "../../serverVariables/ranks";

const VictoryTile = props => {
    // props
    const {victory, changeReaction, noProfile} = props;
    // local
    const [active, setActive] = useState({
        like: victory.reactions.find(vic => vic.user_selected && vic.reaction_type_id === REACTION_TYPES.LIKE),
        wow: victory.reactions.find(vic => vic.user_selected && vic.reaction_type_id === REACTION_TYPES.WOW),
        envy: victory.reactions.find(vic => vic.user_selected && vic.reaction_type_id === REACTION_TYPES.ENVY),
        love: victory.reactions.find(vic => vic.user_selected && vic.reaction_type_id === REACTION_TYPES.LOVE),
    });
    const [reactions, setReactions] = useState({
        like:  0,
        wow:  0,
        envy:  0,
        love: 0,
    });

    useEffect(() => {
        const reactionCount = victory.reactions.reduce((previousValue, current) => {
            if (current.reaction_type_id === REACTION_TYPES.LIKE) previousValue.like = current.count;
            if (current.reaction_type_id === REACTION_TYPES.LOVE) previousValue.love = current.count;
            if (current.reaction_type_id === REACTION_TYPES.WOW) previousValue.wow = current.count;
            if (current.reaction_type_id === REACTION_TYPES.ENVY) previousValue.envy = current.count;
            return previousValue;
        }, reactions);
        setReactions({...reactionCount});
    }, []);

    function reactToVictory(type) {
        if (!changeReaction) return;
        const reset = {like: false, wow: false, envy: false, love: false};
        const copy = {...reactions};
        if (type === REACTION_TYPES.LIKE) {
            const addition = active.like ? -1 : 1;
            Object.keys(active).forEach(key => {
                if (active[key]) copy[key] = copy[key] -1;
            });
            setActive({...reset, like: !active.like});
            setReactions({...copy, like: reactions.like + addition});
        }
        if (type === REACTION_TYPES.WOW) {
            const addition = active.wow ? -1 : 1;
            Object.keys(active).forEach(key => {
                if (active[key]) copy[key] = copy[key] -1;
            });
            setActive({...reset, wow: !active.wow});
            setReactions({...copy, wow: reactions.wow + addition});
        }
        if (type === REACTION_TYPES.ENVY) {
            const addition = active.envy ? -1 : 1;
            Object.keys(active).forEach(key => {
                if (active[key]) copy[key] = copy[key] -1;
            });
            setActive({...reset, envy: !active.envy});
            setReactions({...copy, envy: reactions.envy + addition});
        }
        if (type === REACTION_TYPES.LOVE) {
            const addition = active.love ? -1 : 1;
            Object.keys(active).forEach(key => {
                if (active[key]) copy[key] = copy[key] -1;
            });
            setActive({...reset, love: !active.love});
            setReactions({...copy, love: reactions.love + addition});
        }
        changeReaction(victory.victory.id, type);
    }

    function abbrNum(number, decPlaces) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10,decPlaces);

        // Enumerate number abbreviations
        var abbrev = [ "k", "m", "b", "t" ];

        // Go through the array backwards, so we do the largest first
        for (var i=abbrev.length-1; i>=0; i--) {

            // Convert array index to "1000", "1000000", etc
            var size = Math.pow(10,(i+1)*3);

            // If the number is bigger or equal do the abbreviation
            if(size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number*decPlaces/size)/decPlaces;

                // Handle special case where we round up to the next abbreviation
                if((number === 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                number += abbrev[i];

                // We are done... stop
                break;
            }
        }

        return number;
    }

    function selectVictoryTitle(typeId) {
        if (typeId === VICTORY_TYPES.RANK) {
            return <p className={noProfile ? styles.largeTitle : ''}>{victory.victory.level_achievement.rank.name} Rank</p>
        }
        if (typeId === VICTORY_TYPES.BADGES) {
            return <p className={noProfile ? styles.largeTitle : ''}>{victory.victory.level_achievement.level.name} Badge</p>
        }
        if (typeId === VICTORY_TYPES.STREAK) {
            return <p className={noProfile ? styles.largeTitle : ''}>7 Day Streak</p>
        }
        if (
            typeId === VICTORY_TYPES.MEALTIME_SPACING ||
            typeId === VICTORY_TYPES.EATING_OCCASIONS ||
            typeId === VICTORY_TYPES.VEGETABLES_AND_FRUIT ||
            typeId === VICTORY_TYPES.PROTEIN_SERVINGS ||
            typeId === VICTORY_TYPES.SWEETENED_FOODS ||
            typeId === VICTORY_TYPES.SWEETENED_DRINKS ||
            typeId === VICTORY_TYPES.STRESS_RELEASING ||
            typeId === VICTORY_TYPES.SLEEP ||
            typeId === VICTORY_TYPES.PHYSICAL_ACTIVITY ||
            typeId === VICTORY_TYPES.SPRINT_INTENSITY ||
            typeId === VICTORY_TYPES.STRENGTH_BUILDING ||
            typeId === VICTORY_TYPES.RELATED_SKILLS ||
            typeId === VICTORY_TYPES.FOUNDATIONS ||
            typeId === VICTORY_TYPES.COACH ||
            typeId === VICTORY_TYPES.JUST_FOR_YOU ||
            typeId === VICTORY_TYPES.VIDEOS_WATCHED_8 ||
            typeId === VICTORY_TYPES.VIDEOS_WATCHED_15 ||
            typeId === VICTORY_TYPES.CHECK_IN_8 ||
            typeId === VICTORY_TYPES.CHECK_IN_15 ||
            typeId === VICTORY_TYPES.STEPS_100K ||
            typeId === VICTORY_TYPES.STEPS_150K ||
            typeId === VICTORY_TYPES.STEPS_200K ||
            typeId === VICTORY_TYPES.STEPS_300K
        ) {
            return <p className={noProfile ? styles.largeTitle : ''}>{victory.victory.victory_type.name}</p>
        }
    }

    function rankClass(victory) {
        if (victory.victory.victory_type.id === VICTORY_TYPES.RANK) {
            if (parseInt(victory.victory.level_achievement.rank.id) === RANKS_IDS.SILVER) {
                return styles.silver;
            }
            if (parseInt(victory.victory.level_achievement.rank.id) === RANKS_IDS.GOLD) {
                return styles.gold;
            }
            if (parseInt(victory.victory.level_achievement.rank.id) === RANKS_IDS.PLATINUM) {
                return styles.platinum;
            }
            if (parseInt(victory.victory.level_achievement.rank.id) === RANKS_IDS.DIAMOND) {
                return styles.diamond;
            }
        }
        return '';
    }

    let profileImg = '';
    if (!noProfile) profileImg = victory.victory.user.profile_image || 'https://treo.s3.us-east-2.amazonaws.com/assets/avatar.png';

    return (
        <div className={`${styles.victoryContainer} ${rankClass(victory)}`} data-testID="CommVicTiles" >
            <div className={styles.victory}>
                <img className={styles.noodle} src="https://treo.s3.us-east-2.amazonaws.com/assets/bg-noodles.png" alt=""/>
                {noProfile ? null
                    :
                    <div className={styles.profile}>
                        <div className={styles.profileImage} style={{backgroundImage: `url(${profileImg})`}}/>
                    </div>
                }
                <div className={styles.info}>
                    {noProfile ? null : <p className={styles.name}>{victory.victory.user.nickname}</p>}
                    {selectVictoryTitle(victory.victory.victory_type.id)}
                    <p>Earned {format(parseISO(victory.victory.achievement_date), 'MM/dd/yy')}</p>
                </div>
                <div>
                    <SelectVictoryImage typeId={victory.victory.victory_type.id} className={styles.badge2} victory={victory}/>
                </div>
            </div>
            <div className={`${styles.reactions} ${rankClass(victory)}`} data-testID="CommunityReactionBtn">
                <div>
                    <LikeReaction
                        active={active.like}
                        callback={() => reactToVictory(REACTION_TYPES.LIKE)}
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#6bd0f3'
                        animatedStroke='#e8ecf0'/>
                    <span>{abbrNum(reactions.like, 1)}</span>
                </div>
                <div>
                    <WowReaction
                        active={active.wow}
                        callback={() => reactToVictory(REACTION_TYPES.WOW)}
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#f7cb5d'
                        animatedStroke='#c78430'/>
                    <span>{abbrNum(reactions.wow, 1)}</span>
                </div>
                <div>
                    <EnvyReaction
                        active={active.envy}
                        callback={() => reactToVictory(REACTION_TYPES.ENVY)}
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#adcb54'
                        animatedStroke='#3f4e3d'/>
                    <span>{abbrNum(reactions.envy, 1)}</span>
                </div>
                <div>
                    <LoveReaction
                        active={active.love}
                        callback={() => reactToVictory(REACTION_TYPES.LOVE)}
                        className={styles.icon}
                        color='transparent'
                        stroke='#354052'
                        animatedColor='#e5466c'
                        animatedStroke='transparent'/>
                    <span>{abbrNum(reactions.love, 1)}</span>
                </div>
            </div>
        </div>
    )
}

export default VictoryTile;